const hasChanges = ref<boolean>(false);
const resetFn = ref<(() => void) | null>(null);
const saveFn = ref<(() => Promise<void>) | null>(null);
const isModalOpen = ref(false);
const isLoading = ref(false);

const modalClosePromise = ref(Promise.resolve());
const modalClosePromiseResolve = ref(() => {
    return;
});
watchEffect(() => {
    if (isModalOpen.value) {
        modalClosePromise.value = new Promise((resolve) => {
            modalClosePromiseResolve.value = resolve;
        });
        return;
    }

    modalClosePromiseResolve.value();
});
export default () => {
    function setHasChanges(value: boolean) {
        hasChanges.value = value;
    }

    function setSaveFn(func: () => Promise<void>) {
        saveFn.value = func;
    }

    function setResetFn(func: () => void) {
        resetFn.value = func;
    }

    function setUnsavedChangesHeader(
        value: boolean,
        resetFn: (() => void) | undefined,
        saveFn: (() => Promise<void>) | undefined,
    ) {
        setHasChanges(value);

        if (resetFn) setResetFn(resetFn);
        if (saveFn) setSaveFn(saveFn);
    }

    function setIsLoading(newLoadingState: boolean) {
        isLoading.value = newLoadingState;
    }

    const toggleModal = (value: boolean) => {
        isModalOpen.value = value;
    };

    return {
        hasChanges,
        saveFn,
        resetFn,
        setIsLoading,
        isLoading,
        setUnsavedChangesHeader,
        isModalOpen,
        toggleModal,
        modalClosePromise,
    };
};
